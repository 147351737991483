<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import axios from 'axios';

  import Swal from 'sweetalert2';

  import { validationMixin } from "vuelidate";
  import { email, required, minValue, maxValue, minLength, maxLength, integer, numeric, sameAs } from "vuelidate/lib/validators";



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'quotation',

    props: {
      siteIdentifier: {
        type: String,
        required: true
      },

      customQuotation: {
        type: Boolean,
        default: false
      },
    },

    mixins: [validationMixin],

    created() {
      this.fetchJobTypes();
      this.fetchAreas();

      console.log("customQuotation: ", this.customQuotation);
    },

    data() {
      return {
        fetchedJobTypes: [],
        fetchedAreas: [],

        jobType: null,
        jobTypeCategory: null,
        squareMeters: null,
        postalCode: null,
        city: null,
        phoneNo: null,
        email: null,
        termsAccepted: false,

        sending: false,

        areaPrice: 0
      }
    },

    validations: {
      jobType: {
        required
      },

      jobTypeCategory: {
        isActiveCategory(value) {
          return (this.jobType != null);
        }
      },

      squareMeters: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(10000),
        integer
      },

      postalCode: {
        required,
        isPostalCode(value) {
          if (value === null) {
            return false;
          }

          let trimmedPostalCode = value.replace(/\s/g, '');

          var foundArea = null;
          for (var i = 0; i < this.fetchedAreas.length; i++) {
            let currentArea = this.fetchedAreas[i];

            if (trimmedPostalCode >= currentArea.postal_code_range_start && trimmedPostalCode <= currentArea.postal_code_range_end) {
              foundArea = currentArea.start_cost;
            }
          }

          return (foundArea != null);
        }
      },

      city: {
        required
      },

      phoneNo: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(12)
      },

      email: {
        required,
        email
      },

      termsAccepted: {
        sameAs: sameAs(() => true)
      }
    },

    computed: {
      jobTypes() {
        var types = [];
        types.push({ value: null, text: 'Typ av tjänst' });

        for (var i = 0; i < this.fetchedJobTypes.length; i++) {
          let jobType = this.fetchedJobTypes[i];

          types.push({ value: jobType.id, text: jobType.name });
        }

        return types;
      },

      jobTypeCategories() {
        var categories = [];

        if (this.jobType == null) {
          categories.push({ value: null, text: 'Välj typ av tjänst först' });
        } else {
          var selectedJobType = null;
          for (var i = 0; i < this.fetchedJobTypes.length; i++) {
            let currentJobType = this.fetchedJobTypes[i];

            if (currentJobType.id == this.jobType) {
              selectedJobType = currentJobType;
            }
          }

          if (selectedJobType != null) {
            if (selectedJobType.categories.length > 0) {
              categories.push({ value: null, text: 'Välj ' + selectedJobType.category_prompt });

              for (var i = 0; i < selectedJobType.categories.length; i++) {
                let category = selectedJobType.categories[i];

                categories.push({ value: category.id, text: category.name });
              }
            } else {
              categories.push({ value: null, text: 'Ej valbart' });
            }
          }
        }

        return categories;
      },

      jobTypeCategoriesDisabled() {
        return (this.jobType == null);
      },

      calculatedPrice() {
        this.areaPrice = 0;

        if (this.postalCode == null || this.jobType == null || this.squareMeters == null) {
          return '-';
        }

        var selectedJobType = null;
        for (var i = 0; i < this.fetchedJobTypes.length; i++) {
          let currentJobType = this.fetchedJobTypes[i];

          if (currentJobType.id == this.jobType) {
            selectedJobType = currentJobType;
          }
        }

        if (selectedJobType.bookable) {
          var selectedCategory = null;
          for (var i = 0; i < selectedJobType.categories.length; i++) {
            let currentCategory = selectedJobType.categories[i];

            if (currentCategory.id == this.jobTypeCategory) {
              selectedCategory = currentCategory;
            }
          }

          var selectedPrice = null;
          for (var i = 0; i < selectedCategory.prices.length; i++) {
            let currentPrice = selectedCategory.prices[i];

            if (this.squareMeters >= currentPrice.from_square_meters && this.squareMeters <= currentPrice.to_square_meters) {
              selectedPrice = currentPrice;
            }
          }

          var squareMeterPrice = 0;
          if (selectedPrice != null) {
            var squareMeterPrice = selectedPrice.price;
          }

          if (selectedPrice != null && selectedPrice.square_meter_pricing) {
            squareMeterPrice = squareMeterPrice * this.squareMeters;
          }

          var areaPrice = 0;
          var selectedArea = null;
          let trimmedPostalCode = this.postalCode.replace(/\s/g, '');
          for (var i = 0; i < this.fetchedAreas.length; i++) {
            let currentArea = this.fetchedAreas[i];

            if (trimmedPostalCode >= currentArea.postal_code_range_start && trimmedPostalCode <= currentArea.postal_code_range_end) {
              areaPrice = currentArea.start_cost;
              selectedArea = currentArea;

              this.areaPrice = areaPrice;
            }
          }

          console.log("squareMeterPrice: ", squareMeterPrice);
          console.log("areaPrice: ", areaPrice);

          // Discounts
          if (selectedArea != null) {
            for (var i = 0; i < selectedArea.discounts.length; i++) {
              let discount = selectedArea.discounts[i];

              if (discount.job_type_id == selectedJobType.id) {
                // Discount found!
                var discountPercentage = null;
                if (discount.price_percentage > 100) {
                  console.log("loaded discountPercentage: ", discount.price_percentage);
                  discountPercentage = discount.price_percentage / 100.0;
                } else {
                  discountPercentage = discount.price_percentage / 100.0;
                }

                if (discountPercentage != null) {
                  console.log("discountPercentage: ", discountPercentage);
                  squareMeterPrice = squareMeterPrice * discountPercentage;
                }
              }
            }
          }

          console.log("squareMeterPrice discounted: ", squareMeterPrice);

          return Math.floor(areaPrice + squareMeterPrice);
        } else {
          return 0;
        }
      }
    },

    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v[name];

        return $dirty ? !$error : null;
      },

      changeJobType() {
        var selectedJobType = null;
        console.log("Job Types: ", this.fetchedJobTypes);

        for (var i = 0; i < this.fetchedJobTypes.length; i++) {
          let currentJobType = this.fetchedJobTypes[i];

          if (currentJobType.id == this.jobType) {
            selectedJobType = currentJobType;
          }
        }

        if (selectedJobType.categories.length > 0) {
          var selectedCategory = null;
          for (var i = 0; i < selectedJobType.categories.length; i++) {
            let currentCategory = selectedJobType.categories[i];

            if (currentCategory.default_category) {
              selectedCategory = currentCategory;
            }
          }

          this.jobTypeCategory = selectedCategory.id;
        } else {
          this.jobTypeCategory = null;
        }
      },

      fetchJobTypes() {
        var self = this;

        this.loading = true;

        axios.post('/api/v1/job_types/site/identifier', {
          site_identifier: this.siteIdentifier
        })
        .then(function (response) {
          self.fetchedJobTypes = response.data.data;
        })
        .catch(function (error) {
          self.loading = false;
        });
      },

      fetchAreas() {
        var self = this;

        this.loading = true;

        axios.post('/api/v1/areas/site/identifier', {
          site_identifier: this.siteIdentifier
        })
        .then(function (response) {
          self.fetchedAreas = response.data.data;
        })
        .catch(function (error) {
          self.loading = false;
        });
      },

      createQuotation() {
        if (!this.sending) {
          var self = this;

          this.sending = true;

          this.$v.$touch();

          if (!this.$v.$invalid) {
            axios.post('/api/v1/quotations', {
              job_type_id: this.jobType,
              job_type_category_id: this.jobTypeCategory,
              square_meters: this.squareMeters,
              postal_code: this.postalCode,
              city: this.city,
              phone_no: this.phoneNo,
              email: this.email,
              terms_accepted: this.termsAccepted,
              calculated_price: this.calculatedPrice,
              area_price: this.areaPrice
            })
            .then(function (response) {
              Swal.fire({
                icon: 'success',
                title: 'Tack för din förfrågan!',
                text: 'Vi har skickat en offert med e-post och sms.',
                showConfirmButton: false,
                timer: 5000,
                onClose: () => {
                  self.jobType = null;
                  self.jobTypeCategory = null;
                  self.squareMeters = null;
                  self.postalCode = null;
                  self.city = null;
                  self.phoneNo = null;
                  self.email = null;
                  self.termsAccepted = false;

                  self.$v.$reset();

                  setTimeout(function() {
                    self.$v.$reset();
                    self.sending = false;
                  }, 100);
                }
              });
            })
            .catch(function (error) {
              console.log("Error creating quotation: ", error);

              self.sending = false;
            });
          }
        }
      },

      createCustomQuotation() {
        if (!this.sending) {
          var self = this;

          this.sending = true;

          this.$v.$touch();

          if (!this.$v.$invalid) {
            axios.post('/api/v1/quotations/custom', {
              job_type_id: this.jobType,
              job_type_category_id: this.jobTypeCategory,
              square_meters: this.squareMeters,
              postal_code: this.postalCode,
              city: this.city,
              phone_no: this.phoneNo,
              email: this.email,
              terms_accepted: this.termsAccepted,
              calculated_price: this.calculatedPrice,
              area_price: this.areaPrice
            })
            .then(function (response) {
              Swal.fire({
                icon: 'success',
                title: 'Tack för din förfrågan!',
                text: 'Vi har skickat en offert till kunden med e-post och sms.',
                showConfirmButton: false,
                timer: 5000,
                onClose: () => {
                  self.jobType = null;
                  self.jobTypeCategory = null;
                  self.squareMeters = null;
                  self.postalCode = null;
                  self.city = null;
                  self.phoneNo = null;
                  self.email = null;
                  self.termsAccepted = false;

                  self.$v.$reset();

                  setTimeout(function() {
                    self.$v.$reset();
                    self.sending = false;
                  }, 100);
                }
              });
            })
            .catch(function (error) {
              console.log("Error creating quotation: ", error);

              self.sending = false;
            });
          }
        }
      },
    }
  };
</script>

<template>
  <div>
    <div v-if="siteIdentifier == 'stadcompaniet'">
      <b-card title="Kostnadsfri offert" class="w-100">
        <b-card-text>
          <b-form-select @change="changeJobType" v-model="$v.jobType.$model" :options="jobTypes" :state="validateState('jobType')"></b-form-select>
        </b-card-text>

        <b-card-text>
          <b-form-select v-model="$v.jobTypeCategory.$model" :options="jobTypeCategories" :state="validateState('jobTypeCategory')" :disabled="jobTypeCategoriesDisabled"></b-form-select>
        </b-card-text>

        <b-card-text>
          <b-form-input v-model="$v.squareMeters.$model" :state="validateState('squareMeters')" placeholder="Antal kvadratmeter" trim></b-form-input>
        </b-card-text>

        <b-card-text>
          <b-form-input v-model="$v.postalCode.$model" :state="validateState('postalCode')" placeholder="Postnummer" trim></b-form-input>
        </b-card-text>

        <b-card-text>
          <b-form-input v-model="$v.city.$model" :state="validateState('city')" placeholder="Ort" trim></b-form-input>
        </b-card-text>

        <b-card-text>
          <b-form-input v-model="$v.phoneNo.$model" :state="validateState('phoneNo')" placeholder="Telefonnummer" trim></b-form-input>
        </b-card-text>

        <b-card-text>
          <b-form-input v-model="$v.email.$model" :state="validateState('email')" placeholder="E-post" trim></b-form-input>
        </b-card-text>

        <b-card-text>
          <b-form-checkbox v-model="$v.termsAccepted.$model" :state="validateState('termsAccepted')">
            Jag samtycker till att motta kommunikation i enlighet med <a href="https://stadcompaniet.se/integritetspolicy/" target="_blank">integritetspolicyn</a>.
          </b-form-checkbox>
        </b-card-text>

        <b-card-text>
          <b-button v-if="!customQuotation" @click="createQuotation" class="w-100" variant="primary">Beräkna mitt pris</b-button>
          <b-button v-if="customQuotation" @click="createCustomQuotation" class="w-100 mt-5 mt-md-0" variant="primary">Skapa offert</b-button>
        </b-card-text>
      </b-card>
    </div>

    <div v-if="siteIdentifier == 'effektivstad'">
      <b-card title="Kostnadsfri offert" class="w-100 text-center">
				<b-row class="mt-5 mb-4 text-left">
					<b-col cols="12" md=3 class="pb-3">
						<b-card-text>
							<b-form-select @change="changeJobType" v-model="$v.jobType.$model" :options="jobTypes" :state="validateState('jobType')"></b-form-select>
						</b-card-text>
					</b-col>
					<b-col cols="12" md=3 class="pb-3">
						<b-card-text>
							<b-form-select v-model="$v.jobTypeCategory.$model" :options="jobTypeCategories" :state="validateState('jobTypeCategory')" :disabled="jobTypeCategoriesDisabled"></b-form-select>
						</b-card-text>
					</b-col>
					<b-col cols="12" md=3 class="pb-3">
						<b-card-text>
          		<b-form-input v-model="$v.squareMeters.$model" :state="validateState('squareMeters')" placeholder="Antal kvadratmeter" trim></b-form-input>
        		</b-card-text>
					</b-col>
					<b-col cols="12" md=3 class="pb-3">
						<b-card-text>
							<b-form-input v-model="$v.postalCode.$model" :state="validateState('postalCode')" placeholder="Postnummer" trim></b-form-input>
						</b-card-text>
					</b-col>

					<b-col cols="12" md=3 class="pb-3">
						<b-card-text>
							<b-form-input v-model="$v.city.$model" :state="validateState('city')" placeholder="Ort" trim></b-form-input>
						</b-card-text>
					</b-col>
					<b-col cols="12" md=3 class="pb-3">
						<b-card-text>
							<b-form-input v-model="$v.phoneNo.$model" :state="validateState('phoneNo')" placeholder="Telefonnummer" trim></b-form-input>
						</b-card-text>
					</b-col>
					<b-col cols="12" md=3 class="pb-3">
						<b-card-text>
							<b-form-input v-model="$v.email.$model" :state="validateState('email')" placeholder="E-post" trim></b-form-input>
						</b-card-text>
					</b-col>
				</b-row>

				<b-row>
					<b-col cols="12" md="9" class="text-left">
						<b-card-text>
							<b-form-checkbox v-model="$v.termsAccepted.$model" :state="validateState('termsAccepted')">
								Jag samtycker till att motta kommunikation i enlighet med <a href="https://effektivstad.se/integritetspolicy/" target="_blank">integritetspolicyn</a>.
							</b-form-checkbox>
						</b-card-text>
					</b-col>
					<b-col cols="12" md="3" class="text-right">
						<b-card-text>
							<b-button v-if="!customQuotation" @click="createQuotation" class="w-100 mt-5 mt-md-0" variant="primary">Beräkna mitt pris</b-button>
              <b-button v-if="customQuotation" @click="createCustomQuotation" class="w-100 mt-5 mt-md-0" variant="primary">Skapa offert</b-button>
						</b-card-text>
					</b-col>
				</b-row>
      </b-card>
    </div>
  </div>
</template>

<style module lang="scss">

  /*
  |--------------------------------------------------------------------------
  | Register Request
  |--------------------------------------------------------------------------
  */

</style>
